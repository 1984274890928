




































import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "@/components/home/HomeView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";

const MobileConfirmedOrderAView = () => import("./MobileConfirmedOrderAView.vue");
const MobilePendingOrderAView = () => import("./MobilePendingOrderAView.vue");

class MobileMainProperties {
    loading: boolean = false;
}

export { MobileMainProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-main.css" }]
	},
    data: () => ({
        tab: null
    }),
    components: {
        MobileConfirmedOrderAView,
        MobilePendingOrderAView
    }
})
export default class MobileMainView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    
    private properties = new MobileMainProperties();

    public get title() {
        if (this.$data.tab === 0) {
            return this.$t("text.order");
        } else if (this.$data.tab === 1) {
            return this.$t("text.pending");
        }
        return "-";
    }
}
